import React, { Component } from 'react';
import { Link , NavLink} from "react-router-dom";



import $ from 'jquery'

import smileImage from './../../assets/images/smile.png';
import Logo from './../../assets/images/logo.png';




// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
import { faPhoneSquare, faEnvelope } from "@fortawesome/free-solid-svg-icons";




class Header extends Component {

	componentDidMount(){

		$(window).scroll(function(){
			if ($(window).scrollTop() >= 150) {
				$('.sticky-top').addClass('fixed-header');
				$('nav div').addClass('visible-title');
			}
			else {
				$('.sticky-top').removeClass('fixed-header');
				$('nav div').removeClass('visible-title');
			}
		});

		// $("a.nav-link").click(function(e) {
		// 	$('.navbar-collapse').collapse('hide');
		// });

		//$('.nav-link').click(() => $('.collapse').collapse('toggle'));


		// Collapse responsive navbar when toggler is visible
		const navbarToggler = document.body.querySelector('.navbar-toggler');
		const responsiveNavItems = [].slice.call(
			document.querySelectorAll('#navbarSupportedContent .nav-link')
		);
		responsiveNavItems.forEach(function (responsiveNavItem) {
			responsiveNavItem.addEventListener('click', () => {
				if (window.getComputedStyle(navbarToggler).display !== 'none') {
					navbarToggler.click();
				}
			});
		});
		

		
	}	

	render(){
		return (
			<div className="header">

				<div className='headerTop'>
					<div className='container'>
						<div className='headerTopContent'>
							<ul>
								<li> <Link to="tel:800-443-4434 "> <FontAwesomeIcon icon={faPhoneSquare} /> <span className='tplink'>800-443-4434</span> </Link></li>
								<li>  <span className='emailText'>email us at &nbsp;</span><Link to="mailto:support@timego.com "> <span className='emailicon'><FontAwesomeIcon icon={faEnvelope} /></span> <span className='tplink'> support@timego.com</span></Link></li>
								<li>Can’t wait to hear from you  <span className='smaillimage'><img src={smileImage} alt="Smile"/></span></li> 
							</ul>
						</div>
					</div>
				</div>
				<div className='navHolder'>
					<nav className="navbar navbar-expand-lg sticky-top" id="mainNav">
						<div className='container'>
							<Link className="navbar-brand" to="/">
								<img src={Logo} alt=""/>
							</Link>
							<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className="navbar-nav me-auto marginLeft">
									<li className="nav-item"><NavLink className="nav-link" to="/">Home</NavLink></li>
									<li className="nav-item"><NavLink className="nav-link" to="/pricing">Pricing</NavLink></li>
									<li className="nav-item"><NavLink className="nav-link" to="/blog">Blog</NavLink></li>
								</ul>
								<div className='d-flex align-items-center'>
									<div className='headerLogin'>
										<Link to="/">Login</Link>
									</div>
									<div className='headergetStarted'>
										<Link to="/">Get Started Free</Link>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		);
		}
	}

export default Header;
