//import React, { useState } from 'react';
//import { Link } from "react-router-dom";


// Import the FontAwesomeIcon component
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
//import { faStar } from "@fortawesome/free-solid-svg-icons";



function Blog() {
  

  return (
    <div className='blog'>
        <div className='pricPageBanner bannerSection'>
          <div className='container'>
            <h1>Blog Page</h1>
          </div>
        </div>
    </div>
  );
}

export default Blog;
