import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
import { faStar } from "@fortawesome/free-solid-svg-icons";



import bannerImage from './../../assets/images/bannerImage.png';
import featuredMidImage from './../../assets/images/featuredMidImage.png';
import emplyeeBelowImage from '../../assets/images/emplyeeBelowImage.png';
import emplyeePartionImage from '../../assets/images/partitionBg.png';
import sideMobileImage from '../../assets/images/sideMobile.png';
import sliderImage_1 from '../../assets/images/sliderImage_1.png';
import sliderImage_2 from '../../assets/images/sliderImage_2.png';
import sliderImage_3 from '../../assets/images/sliderImage_3.png';
import sliderImage_4 from '../../assets/images/sliderImage_4.png';
import icon_1 from '../../assets/images/icon_1.png';
import icon_2 from '../../assets/images/icon_2.png';
import icon_3 from '../../assets/images/icon_3.png';
import icon_4 from '../../assets/images/icon_4.png';
import icon_5 from '../../assets/images/icon_5.png';
import icon_6 from '../../assets/images/icon_6.png';
import icon_7 from '../../assets/images/icon_7.png';
import icon_8 from '../../assets/images/icon_8.png';
import managementImage from '../../assets/images/managementImage.png';
import quoteIcon from '../../assets/images/quote.png';
import infoIcon_1 from '../../assets/images/infoIcon_1.png';
import infoIcon_2 from '../../assets/images/infoIcon_2.png';
import infoIcon_3 from '../../assets/images/infoIcon_3.png';
import infoIcon_4 from '../../assets/images/infoIcon_4.png';
import infoIcon_5 from '../../assets/images/infoIcon_5.png';
import infoIcon_6 from '../../assets/images/infoIcon_6.png';
import runImage from '../../assets/images/runImage.png';
import imageBoxImage_1 from '../../assets/images/boxImage_1.png';
import imageBoxImage_2 from '../../assets/images/boxImage_2.png';
import imageBoxImage_3 from '../../assets/images/boxImage_3.png';
import imageBoxImage_4 from '../../assets/images/boxImage_4.png';
import commingImage_1 from '../../assets/images/commingImage_1.png';
import commingImage_2 from '../../assets/images/commingImage_2.png';
import customerImage from '../../assets/images/customerImage.png';
import aboutBottomImage from '../../assets/images/aboutBottomImage.png';


function Home() {

  

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 1500,
    cssEase: "linear"
    
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    fade: true,


  };


  useEffect(() => {
    AOS.init();
  }, [])
  

  return (
    <div className="home">
      <div className="bannerSection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2" data-aos="fade-in" data-aos-duration="1000">
              <div className="bannerImage">
                <img src={bannerImage} alt="" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1" data-aos="fade-in" data-aos-duration="1000">
              <h1>Run your business operations using <span>less</span><br/> time and effort. </h1>
              <p><strong>TimeGO</strong> is simple to set up and very easy to use. Built for <br />teams of any size and industry.</p>
              <Link to="/" className="btnGetStarted">Get Started Free</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="featuredSection" data-aos="fade-up" data-aos-duration="1000">
        <div className="container">
          <div className="row g-0">
            <div className="col-xl-6">
              <div className="featuredLeft">
                <div className="featuredMidImage">
                  <img src={featuredMidImage} alt="" />
                </div>
                <div className="featuredHolder featuredLeftPadding">
                  <h5>Some features are </h5>
                  <h2>100% <span>Free</span></h2>
                  <div className="pointsHolder">
                    <ul>
                      <li>Employee Scheduling</li>
                      <li>Unlimited Employees</li>
                      <li>Unlimited Clients</li>	
                      <li>Unlimited Locations</li>
                      <li>Unlimited Contacts</li>	
                      <li>Employee Performance Log</li>		
                      <li>Employee and Job Rates</li>
                      <li>Mobile: Employees can View Upcoming Schedule</li>	
                      <li>Mobile: Employees Submit Time-off Requests</li>
                      <li>Mobile: Chat and Job Chat</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="featuredRight">
                <div className="featuredHolder featuredRightPadding">
                  <h5>Get all these features for </h5>
                  <h2>$6 <span>per active user per month</span></h2>
                    <div className="pointsHolder">
                      <div className="pointleft">
                        <ul>
                          <li>Mobile Employee Time Tracking</li>
                          <li>Custom Forms, Go Paperless</li>		
                          <li>Employee Records and Documents</li>	
                          <li>Job Log and Documents</li>	
                          <li>Submit Job Expenses</li>		
                          <li>Shift To-Do Lists</li> 	
                          <li>Employee To-Do Lists</li>	
                          <li>GPS Tracking Review</li> 	
                          <li>Web and Mobile Alerts</li>	
                          <li>Provide Clients Direct Access</li> 	
                        </ul>
                      </div>
                      <div className="pointright">
                        <ul>
                          <li>Map View</li>	
                          <li>Equipment Log</li> 
                          <li>Patrol with Virtual Checkpoints/ NFC tags/QR codes</li>	
                          <li>Invoicing	</li>
                          <li>Profit Tracking</li> 	
                          <li>Reports	</li>
                        </ul>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="emplyeeSection" data-aos="fade-up" data-aos-duration="1000">
        <div className="container">
          <div className="emplyeeSectioninner smallContainer">
            <div className="paddingContainer">
              <div className="text-center">
                <h2>Employee Scheduling</h2>
                <p><span>Always 100% free!</span> Easily manage your employee schedule (whether it’s a few employees, or hundreds of them) from one screen on a computer or on a mobile phone on-the-go!</p>
              </div>
            </div>
            <div className="emplyeecolumnsection">
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <ul>
                    <li>View scheduled shifts with employees and actual hours worked on the same screen</li>
                    <li>Notify employees of their upcoming shifts and allow them to confirm or decline schedule changes</li>
                    <li>View schedule sorted first by either employees or jobs, and filter your list by employee groups or job groups</li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>Reduce overtime by reviewing an employee’s forecasted hours as of each day in the week</li>
                    <li>Color coded schedule lets you know at a glance which shifts are unfilled, missing employee clock-in, unconfirmed or confirmed by employee</li>
                    <li>Get live alerts when employees accept or decline their schedule</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="emplyeeBelowImage">
              <img src={emplyeeBelowImage} alt="" />
            </div>
            <div className="sideMobileImage">
              <img src={sideMobileImage} alt="" />
            </div>
          </div>
        </div>
        <div className="emplyeePartionImage">
          <img src={emplyeePartionImage} alt="" />
        </div>
      </div>
      <div className="teamworkSection" data-aos="fade-up" data-aos-duration="1000">
        <div className='container'>
          <div className='teamworkSectionInner smallContainer'>
            <div className='row align-items-center'>
              <div className='col-lg-5'>
                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settings2}>
                  <div className='item'>
                    <div className='sliderImage'>
                      <img src={sliderImage_1} alt="" />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='sliderImage'>
                      <img src={sliderImage_2} alt="" />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='sliderImage'>
                      <img src={sliderImage_3} alt="" />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='sliderImage'>
                      <img src={sliderImage_4} alt="" />
                    </div>
                  </div>
                </Slider>
              </div>
              <div className='col-lg-7'>
                <div className='sliderNavHolder'>
                  <h2>Making teamwork easier and more accurate</h2>
                  <Slider asNavFor={nav1} ref={(slider2) => setNav2(slider2)} {...settings} swipeToSlide={true}  focusOnSelect={true} >
                    <div className='navItem'>
                      <div className='icon_card cardleft'>
                        <div className='icon'>
                          <img src={icon_1} alt="icon"/>
                        </div>
                        <h4>Time-tracking and GPS tracking</h4>
                        <p>Easily track, monitor employees and manage labor costs with TimeGO. </p>
                      </div>
                    </div>
                    <div className='navItem'>
                      <div className='icon_card cardright'>
                        <div className='icon'>
                          <img src={icon_2} alt="icon"/>
                        </div>
                        <h4>Team Communication via Employee Chat and Job Chat</h4>
                        <p>Ensure that all managers are on the same page with any communications with an employee.</p>
                      </div>
                    </div>
                    <div className='navItem'>
                      <div className='icon_card cardleft'>
                        <div className='icon'>
                          <img src={icon_3} alt="icon"/>
                        </div>
                        <h4>Custom Forms, go Paperless!</h4>
                        <p>Build your own custom forms to replace paper forms or other less versatile digital forms.</p>
                      </div>
                    </div>
                    <div className='navItem'>
                      <div className='icon_card cardright'>
                        <div className='icon'>
                          <img src={icon_4} alt="icon"/>
                        </div>
                        <h4>Employee Records and Documents</h4>
                        <p>Manage all employee documents, forms, IDs, certificates, etc in one place. </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
      <div className='managementSection' data-aos="fade-up" data-aos-duration="1000">
        <div className='managementSection_inner'>
          <div className='managemtImage'>
            <img src={managementImage} alt="" />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2>The complete team management platform</h2>
                <div className='iconBoxHolder'>
                  <div className='icon_card cardleft'>
                    <div className='icon'>
                      <img src={icon_5} alt="icon"/>
                    </div>
                    <h4>Employee Time-Off Requests</h4>
                    <p>Allow employees to submit leave requests from their TimeGO App. </p>
                  </div>
                  <div className='icon_card cardright'>
                    <div className='icon'>
                      <img src={icon_6} alt="icon"/>
                    </div>
                    <h4>Clients, Locations and Contact Lists</h4>
                    <p>Manage your list of clients and a full searchable list of contacts for each client </p>
                  </div>
                  <div className='icon_card cardleft'>
                    <div className='icon'>
                      <img src={icon_7} alt="icon"/>
                    </div>
                    <h4>To-Do Lists for Jobs and Employees</h4>
                    <p>To-do lists greatly improve accountability and quality of service. </p>
                  </div>
                  <div className='icon_card cardright'>
                    <div className='icon'>
                      <img src={icon_8} alt="icon"/>
                    </div>
                    <h4>Map View of Employees and Client Locations</h4>
                    <p>Review employees and client locations on a Google Map to help with sending employees closest to a job site </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="customerSection" data-aos="fade-up" data-aos-duration="1000">
        <div className='container'>
          <div className='text-center'><h2>Stories from our customers</h2></div>
          <div className='customerBoxHolder'>
            <div className='customerBox'>
              <div className='customerTop'>
                <div className='quoteIcon'>
                  <img src={quoteIcon} alt="" />
                </div>
                <div className='starRating'>
                  <ul>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                  </ul>
                </div>
                <h3>Love it.</h3>
                <p>Saves me a ton of time managing employees’ schedule. I can also make updates on-the-go from my cell phone at any time. I’m way more organized than before.</p>
              </div>
              <div className='customerBottom'>
                <div className='customerName'>HIRA ASIF <span>Scheduler</span></div>
              </div>
            </div>
            <div className='customerBox'>
              <div className='customerTop'>
                <div className='quoteIcon'>
                  <img src={quoteIcon} alt="" />
                </div>
                <div className='starRating'>
                  <ul>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                    <li><FontAwesomeIcon icon={faStar} /></li>
                  </ul>
                </div>
                <h3>Helps me stay ahead</h3>
                <p>Since everything is one place, I can respond to client questions much quicker. TimeGO alerts me to employee and job issues as they happen so I can take action before hearing about it from the client.</p>
              </div>
              <div className='customerBottom'>
                <div className='customerName'>HIRA ASIF <span>Scheduler</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='informationSection' data-aos="fade-up" data-aos-duration="1000">
        <div className='container'>
          <div className='text-center'>
            <h2>Information at your finger tips</h2>
          </div>
          <div className='informationHolder smallContainer'>
            <div className='row'>
              <div className='col-md-6 col-lg-4 mb-4'>
                <div className='infoIconbox' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                  <div className='infoIcon'>
                    <img src={infoIcon_1} alt="" />
                  </div>
                  <h3>Employee Rates</h3>
                  <p>Manage employee pay rates and review labor spend and expenses at any time</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-4'>
                <div className='infoIconbox'  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                  <div className='infoIcon'>
                    <img src={infoIcon_2} alt="" />
                  </div>
                  <h3>Jobs List and Rates</h3>
                  <p>Manage a list of Jobs for each client location</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-4'>
                <div className='infoIconbox'  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                  <div className='comingSoonBadge'>Coming soon</div>
                  <div className='infoIcon'>
                    <img src={infoIcon_3} alt="" />
                  </div>
                  <h3>Invoices</h3>
                  <p>Manage a job log to record all job-related activity</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-4'>
                <div className='infoIconbox'  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="700">
                  <div className='infoIcon'>
                    <img src={infoIcon_4} alt="" />
                  </div>
                  <h3>Calendar of Events</h3>
                  <p>View a Work Calendar by Week, Month, or list of key events</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-4'>
                <div className='infoIconbox'  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
                  <div className='comingSoonBadge'>Coming soon</div>
                  <div className='infoIcon'>
                    <img src={infoIcon_5} alt="" />
                  </div>
                  <h3>Equipment List and Log </h3>
                  <p>Track your company equipment list and manage an activity log for each item</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-4'>
                <div className='infoIconbox'  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1100">
                  <div className='infoIcon'>
                    <img src={infoIcon_6} alt="" />
                  </div>
                  <h3>Timesheet/Payroll Reports</h3>
                  <p>Review all hours worked by any time period or payroll period</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='runSection' data-aos="fade-up" data-aos-duration="1000">
        <div className='container'>
          <div className='runHolder smallContainer'>
            <div className='row align-items-center'>
              <div className='col-lg-5'>
                <div className='runImage'>
                  <img src={runImage} alt="" />
                </div>
              </div>
              <div className='col-lg-7'>
                <div className='accroSection'>
                  <h2>Run a Tight Ship</h2>
                  <div className="accordion accordion-flush" id="runAccrodian">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><span>Job Log</span></button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Manage a job log to record all job-related activity with date stamps, useful for site visit records, client interaction records, or to record important milestones on a Job</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span>Job Expenses</span></button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span>Activity Alerts</span></button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span>GPS Tracking Review</span></button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"><span>Checkpoint Patrol using NFC tags, QR codes or Virtual GPS checkpoints</span></button>
                      </h2>
                      <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"><span>Provide Clients Direct Access to Form Results, Checkpoint Scans or GPS Tracking</span></button>
                      </h2>
                      <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"><span>Restricted View for Managers and Employees</span></button>
                      </h2>
                      <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#runAccrodian">
                        <div className="accordion-body">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='imageInfoSection'  data-aos="fade-up" data-aos-duration="1000">
        <div className='imageInfoSection_inner'>
          <div className='container'>
            <div className='smallContainer'>
              <div className='row justify-content-between'>
                <div className='col-lg-5 mb-7'>
                  <div className='imageBox'>
                    <div className='imageboxImage'>
                      <img src={imageBoxImage_1} alt="" />
                    </div>
                    <h3>Eliminate Paperwork </h3>
                    <p>Say goodbye to loads of paper record-keeping and document hunting. Take pics from your TimeGO app and upload documents to the TimeGO cloud database. Build custom forms that can be filled out and signed from a phone or tablet. Your data is secure, easily searchable and accessible when you need it. </p>
                  </div>
                </div>
                <div className='col-lg-5 mb-7'>
                  <div className='imageBox'>
                    <div className='imageboxImage'>
                      <img src={imageBoxImage_2} alt="" />
                    </div>
                    <h3>Eliminate Paperwork </h3>
                    <p>Say goodbye to loads of paper record-keeping and document hunting. Take pics from your TimeGO app and upload documents to the TimeGO cloud database. Build custom forms that can be filled out and signed from a phone or tablet. Your data is secure, easily searchable and accessible when you need it. </p>
                  </div>
                </div>
                <div className='col-lg-5 mb-7'>
                  <div className='imageBox'>
                    <div className='imageboxImage'>
                      <img src={imageBoxImage_3} alt="" />
                    </div>
                    <h3>Eliminate Paperwork </h3>
                    <p>Say goodbye to loads of paper record-keeping and document hunting. Take pics from your TimeGO app and upload documents to the TimeGO cloud database. Build custom forms that can be filled out and signed from a phone or tablet. Your data is secure, easily searchable and accessible when you need it. </p>
                  </div>
                </div>
                <div className='col-lg-5 mb-7'>
                  <div className='imageBox'>
                    <div className='imageboxImage'>
                      <img src={imageBoxImage_4} alt="" />
                    </div>
                    <h3>Eliminate Paperwork </h3>
                    <p>Say goodbye to loads of paper record-keeping and document hunting. Take pics from your TimeGO app and upload documents to the TimeGO cloud database. Build custom forms that can be filled out and signed from a phone or tablet. Your data is secure, easily searchable and accessible when you need it. </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div className='commingSoonSection' data-aos="fade-up" data-aos-duration="1000">
        <div className='container'>
          <div className='smallContainer'>
            <div className='text-center mb-7'>
              <h2>Coming Soon</h2>
            </div>
            <div className='row justify-content-between align-items-center mb-7'>
              <div className='col-lg-5 text-center'>
                <div className='commingImage'><img src={commingImage_1} alt="" /></div>
              </div>
              <div className='col-lg-5'>
                <h3>Equipment List and Log </h3>
                <p>Track your company equipment list and manage an activity log for each item</p>
                <ul>
                  <li>Manage a full list of equipment that is issued to employees, job sites, etc</li>
                  <li>For each equipment item, add any number of log entries with date stamps</li>
                </ul>
              </div>
            </div>
            <div className='row justify-content-between align-items-center'>
              <div className='col-lg-5 order-lg-2'>
                <div className='commingImage2'><img src={commingImage_2} alt="" /></div>
              </div>
              <div className='col-lg-6 order-lg-1'>
                <h3>Invoices </h3>
                <p>Powerful and flexible invoicing system that allows you to create detailed invoices with daily shifts/hours scheduled or actually worked </p>
                <ul>
                  <li>Automatically add all hours scheduled or actually worked on the selected jobs to the invoice</li>
                  <li>Edit any lines or amounts as needed</li>
                  <li>Add discounts or taxes</li>
                  <li>Send invoices by email from TimeGO</li>
                  <li>Track invoice payments</li>
                  <li>Quickly review all outstanding invoices and amounts</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='customerDevelopmentSection' data-aos="fade-up" data-aos-duration="1000">
        <div className='container'>
          <div className='customerInner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='customerImage'>
                  <img src={customerImage} alt="" />
                </div>
              </div>
              <div className='col-lg-6'>
                <h2>Custom Development</h2>
                <p>We can tailor TimeGO software to make sure it meets your specific requirements including:</p>
                <ul>
                  <li>Specialty integrations with other software</li>
                  <li>Customized Reports</li>
                  <li>Custom improvements to current functionality</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='abtSection' data-aos="fade-up" data-aos-duration="1000">
        <div className='aboutContainer'>
          <div className='text-center'>
            <h2>About Us</h2>
            <p>TimeGo was conceived and developed by business owners like you, who felt the pain of running business operations the old way.  They had to rely on manual processes, or use several different digital tools and apps to get their schedules set, their work done, and their efficiency tracked.  TimeGo's expert software team built a platform from scratch, using the latest and best technologies available to create an integrated, smooth software experience.  TimeGo allows business owners, employers, and managers to focus on their work and achieve their companies’ operation and growth objectives, rather than struggling with multiple various software programs. </p>
            <div className='aboutBottomImage'>
              <img src={aboutBottomImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
