import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/css/style.css';

import ScrollToTop from "react-scroll-to-top";

import { Header, Footer } from "./components";
import Home from "./components/view/Home";
import Pricing from "./components/view/Pricing";
import Blog from "./components/view/Blog";
import NoPage from "./components/view/NoPage";


function App() {
  return (
    <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="blog" element={<Blog />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <ScrollToTop smooth />
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
