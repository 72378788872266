import React, {  useEffect } from 'react';
import { Link } from "react-router-dom";


import AOS from 'aos';
import 'aos/dist/aos.css';

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
import { faTwitter, faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import footerImage from './../../assets/images/footerImage.png';
import appStore from './../../assets/images/appstore.png';
import playStore from './../../assets/images/playstore.png';
import Logo from './../../assets/images/logo.png';

function Footer() {

  useEffect(() => {
    AOS.init();
  }, [])
  

  return (
    <div className="footer">
      <div className="footerTop">
        <div className="smallContainer">
          <div className="row">
            <div className="col-lg-8 order-lg-2">
              <div className="footerContent">
                <h2><span>TimeGO:</span> Your Digital Transformation Ally</h2>
                <p>This app is available for all of your devices</p>
                <div className="appButonHolder">
                  <ul>
                    <li><Link to="https://play.google.com/store/games" target='_blank'><img src={appStore} alt="" /></Link></li>
                    <li><Link to="https://www.apple.com/in/app-store/" target='_blank'><img src={playStore} alt="" /></Link></li>
                  </ul>
                </div>
                <h3>Connect with us</h3>
                <div className="social">
                  <ul>
                    <li><Link to="https://www.facebook.com" target='_blank'><FontAwesomeIcon icon={faFacebookF} /></Link></li>
                    <li><Link to="https://www.instagram.com/" target='_blank'><FontAwesomeIcon icon={faInstagram} /></Link></li>
                    <li><Link to="https://twitter.com/" target='_blank'><FontAwesomeIcon icon={faTwitter} /></Link></li>
                    <li><Link to="https://www.linkedin.com/" target='_blank'><FontAwesomeIcon icon={faLinkedin} /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-1">
              <div className="footerImage" data-aos="fade-up"  data-aos-duration="1000"><img src={footerImage} alt="" /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="container">
          <div className="fooerContent">
            <div className="footerLogo">
              <Link to="/">
								<img src={Logo} alt=""/>
							</Link>
            </div>
            <div className="footerNav">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/pricing">Pricing </Link></li>
                <li><Link to="/blog">Blog</Link></li>
              </ul>
            </div>
            <div className="copyright">© 2023 TimeGO LLC</div>
          </div>
        </div>
      </div>
		</div>
  );
}

export default Footer;
